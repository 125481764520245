import { keyframes } from 'styled-components';

export const lazyLoad = keyframes`

    0% {
        opacity: 0;

    }
        100% {
            opacity: 1;
        }

`;
